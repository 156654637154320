import React from "react";
import { makeStyles, Container, Typography } from "@material-ui/core";
import { newsData } from "./data";
import { Themecolors } from "../Theming/Theming";
import { motion } from "framer-motion";

const useStyles = makeStyles({
  AppContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  itemContainer: {
    border: `2px solid ${Themecolors.darkestRed}`,
    alignSelf: "flex-start",
    borderRadius: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "80%",
    backgroundImage: "linear-gradient(to bottom right, #000, #1e2124 )",
    boxShadow: "5px 5px 5px #000",
  },
  itemContainer2: {
    border: `2px solid ${Themecolors.darkestRed}`,
    alignSelf: "flex-end",
    borderRadius: 16,
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "center",
    width: "80%",
    backgroundImage: "linear-gradient(to bottom right, #000, #1e2124 )",
    boxShadow: "5px 5px 5px #000",
    marginTop: "3%",
    marginBottom: "3%",
  },
  typography: {
    color: "#f0f8ff",
    userSelect: "none",
    textShadow: "4px 4px 4px rgba(0,0,0,0.25)",
    background: `-webkit-linear-gradient(0.05turn,${Themecolors.brightRed},${Themecolors.darkestRed})`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "IMMORTAL",
  },
  typography2: {
    color: "#f0f8ff",
    userSelect: "none",
  },
  textContainer: {
    width: "80%",
    padding: "1%",
    justifyContent: "space-between",
    height: "100%",
  },
  textContainer2: {
    width: "79%",
    padding: "1%",
    paddingLeft: "2%",
    justifyContent: "space-between",
    height: "100%",
  },
  image: {
    width: "20%",
    padding: "1%",
  },

  image2: {
    width: "15%",
    paddingTop: "1%",
    paddingBottom: "1%",
    paddingRight: "1%",
  },
});

const transition = {
  type: "spring",
  velocity: 2,
  ease: "easeIn",
};

const animation = { x: [300, -50, 0] };
const animationReverse = { x: [-300, 50, 0] };

const NewsPage = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" disableGutters className={classes.AppContainer}>
      {newsData.map((item, i) => (
        <motion.div
          animate={i % 2 === 0 ? animation : animationReverse}
          transition={transition}
          key={i}
          className={
            i % 2 === 0 ? classes.itemContainer : classes.itemContainer2
          }
        >
          <div className={i % 2 === 0 ? classes.image : classes.image2}>
            <img src={item.img} width={150} />
          </div>
          <div
            className={
              i % 2 === 0 ? classes.textContainer : classes.textContainer2
            }
          >
            <div>
              <Typography variant="h4" className={classes.typography}>
                {item.title}
              </Typography>
            </div>
            <div>
              <Typography variant="h6" className={classes.typography2}>
                {item.description}
              </Typography>
            </div>
          </div>
        </motion.div>
      ))}
    </Container>
  );
};

export default NewsPage;
