import { makeStyles, Tabs, Tab, AppBar, Box } from "@material-ui/core";
import React, { useState } from "react";
import LandingFooter from "./LandingPage/LandingFooter";
import LandingContent from "./LandingPage/LandingContent";
import TEAM_RED_HEAD from "./assets/images/TEAM_RED_HEAD.png";
import smoke from "./assets/images/smoke2.png";
import LandingButton from "./LandingPage/LandingButton";
import LandingLinkButton from "./LandingPage/LandingLinkButton";
import AboutPage from "./AboutPage/AboutPage";
import GalleryPage from "./GalleryPage/GalleryPage";
import NewsPage from "./NewsPage/NewsPage";
import { motion } from "framer-motion";
import ApplyPage from "./ApplyPage/ApplyPage";

const useStyles = makeStyles({
  bodyContainer: {
    flexGrow: 1,
    margin: 0,
    padding: 0,
    width: "100%",
    height: "100%",
    alignContent: "center",
    justifyContent: "center",
    backgroundColor: "#222529",
  },
  landingTab: {
    width: "100%",
    height: "74vh",
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  tabBarIndicator: {
    "& .MuiTabs-indicator": {
      backgroundColor: "transparent",
    },
  },
  tab: {
    height: "fit-content",
    width: "min-content",
    alignSelf: "center",
    opacity: 1,
    padding: 0,
  },
  box: {
    width: "100%",
    minHeight: "80vh",
  },
  smoke: {
    marginTop: "5vh",
    marginRight: "1.5vw",
    width: "5vw",
    zIndex: 100,
    position: "absolute",
    opacity: 0,
    paddingLeft: "5vw",
    paddingRight: "0vw",
    paddingBottom: "6.3vh",
    marginLeft: "4.5vw",
    transform: `rotate(25deg)`,
  },
});

const animateSmoke = { opacity: [0, 0.5, 1, 0.5, 0] };

function App() {
  const [value, setValue] = useState(2);
  const classes = useStyles();

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div {...other}>
        {value === index && <Box className={classes.box}>{children}</Box>}
      </div>
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.bodyContainer}>
      <AppBar
        position="sticky"
        color="transparent"
        style={{
          boxShadow: "none",
          paddingTop: "1%",
          height: "20vh",
          backgroundColor: "#222529",
          zIndex:100
        }}
      >
        <Tabs
          className={classes.tabBarIndicator}
          centered
          value={value}
          onChange={handleChange}
          aria-label="tabBar"
          style={{ backgroundColor: "transparent" }}
        >
          <Tab
            disableRipple
            className={classes.tab}
            label={<LandingButton title="ABOUT US" />}
          />
          <Tab
            disableRipple
            className={classes.tab}
            label={<LandingButton title="GALLERY" />}
          />
          <Tab
            disableRipple
            style={{ opacity: 1, overflow: "revert" }}
            label={
              <>
                <img
                  src={TEAM_RED_HEAD}
                  alt="Home!"
                  style={{ width: "6vw", height: "auto" }}
                />
                <motion.img
                  src={smoke}
                  className={classes.smoke}
                  whileHover={{ opacity: [0, 0.5, 1, 0.5, 0] }}
                />
              </>
            }
          />
          <Tab
            disableRipple
            className={classes.tab}
            label={<LandingLinkButton title="APPLY" />}
          />
          <Tab
            disableRipple
            className={classes.tab}
            label={<LandingButton title="NEWS" />}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <AboutPage />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <GalleryPage />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className={classes.landingTab}>
          <div></div>
          <LandingContent />
          <LandingFooter />
        </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ApplyPage />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <NewsPage />
      </TabPanel>
    </div>
  );
}

export default App;
