import React from "react";
import { makeStyles, Container, Typography} from "@material-ui/core";
import { Themecolors } from "../Theming/Theming";
import { aboutUs, aboutUs2, aboutUs3 } from "./data";
import bitch from "../assets/gallery/SylvanasHC.png";

const useStyles = makeStyles({
  AppContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "2vh",
    paddingTop: "2vh",
  },
  title: {
    fontFamily: "IMMORTAL",
    fontStyle: "normal",
    fontWeight: 500,
    alignItems: "center",
    textAlign: "center",
    textShadow: "4px 4px 4px rgba(0,0,0,0.25)",
    background: `-webkit-linear-gradient(0.05turn,${Themecolors.brightRed},${Themecolors.darkestRed})`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    userSelect: "none",
  },
  content: {
    fontFamily: "Roboto",
    marginTop: "1%",
    paddingLeft: "15%",
    paddingRight: "15%",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "1.2em",
    textAlign: "justify",
    color: "#f0f8ff",
    userSelect: "none", // to prevent selection of text in gi text chunks
  },
  contentContainer: {
    marginTop: "0.5%",
    justifyItems: "center",
  },
  img: {
    alignSelf: "center",
  },
});

const AboutPage = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="xl" disableGutters className={classes.AppContainer}>
      <Typography variant="h3" className={classes.title}>
        ABOUT US
      </Typography>
      <Container
        maxWidth="md"
        disableGutters
        className={classes.contentContainer}
      >
        <img src={bitch} width={"100%"} className={classes.img} />
        <Typography className={classes.content}>{aboutUs}</Typography>
        <Typography className={classes.content}>{aboutUs2}</Typography>
        <Typography className={classes.content}>{aboutUs3}</Typography>
      </Container>
    </Container>
  );
};

export default AboutPage;
