import React from "react";
import { Container, Button, makeStyles, Link } from "@material-ui/core";
import discord from "../assets/images/discord.png";
import wow from "../assets/images/wow.png";
import youtube from "../assets/images/youtube.png";
import { youtubelink, discordlink, wowprogresslink } from "./data";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignSelf: "center",
  },
  discord: {
    width: "100%",
    height: "auto",
  },
  youtube: {
    width: "100%",
    height: "auto",
  },
  wow: {
    width: "100%",
    height: "auto",
  },
  buttonStyle: {
    disableElevation: true,
    width: "8%",
    padding: "5px",
    disableRipple: true,
    disableFocusRipple: true,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
});

const LandingFooter = () => {
  const classes = useStyles();

  return (
    <Container disableGutters maxWidth="md" className={classes.container}>
      <Button
        disableRipple
        href={`${youtubelink}`}
        target="_blank"
        className={classes.buttonStyle}
      >
        <img src={youtube} alt="" className={classes.youtube} />
      </Button>
      <Button
        disableRipple
        href={`${discordlink}`}
        target="_blank"
        className={classes.buttonStyle}
      >
        <img src={discord} alt="" className={classes.discord} />
      </Button>
      <Button
        disableRipple
        target="_blank"
        href={`${wowprogresslink}`}
        className={classes.buttonStyle}
      >
        <img src={wow} alt="" className={classes.wow} />
      </Button>
    </Container>
  );
};

export default LandingFooter;
