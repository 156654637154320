import Redlogo from "../assets/images/TEAM_RED_HEAD.png";

export const newsData = [
  {
    img: Redlogo,
    title: "Gnarlroot Mythic is down!",
    description:
      "Gnarlroot Mythic is down after 3 pulls! First down.",
  },
  {
    img: Redlogo,
    title: "Igira the Cruel Mythic is down!",
    description:
      "Igira wasn't so crual after all and she fell afte 10 pulls! Two bosses down!",
  },
  {
    img: Redlogo,
    title: "Volcoross Mythic is down!",
    description:
      "Volcoross died after 8 pulls. Three down!",
  }
];
