import React from "react";
import { Container, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    iFrameContainer: {
        backgroundColor: "#E8E9EB",
        alignSelf: "center",
        paddingTop: 30,
        border: "1px solid black",
        borderRadius: 12,
        boxShadow: "5px 5px 5px #000",
    },
});

const ApplyPage = () => {
    const classes = useStyles();
    const [submited, setSubmited] = React.useState(false);
    console.log(submited)

    return (
        <Container maxWidth="md" className={classes.iFrameContainer}>
            <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLScqtmMlqoSQ2hFLz8rTkyjlQ3aakh2ZmNkHuxyfCy3gSeddeg/viewform?embedded=true"
                width="100%"
                height={`${submited ? "250" : "2826px"}`}
                frameborder="0"
                marginheight="0"
                scrolling="no"
                marginwidth="0"
                title="Apply Form"
            >
                Loading…
            </iframe>
        </Container>
    );
};

export default ApplyPage;
