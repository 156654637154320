import React, { useState } from "react";
import {
  makeStyles,
  Container,
  Typography,
  Tabs,
  Tab,
  AppBar,
  Box,
  Grid,
  Dialog,
} from "@material-ui/core";
import { Themecolors } from "../Theming/Theming";
import { imageItems, youtubeItems, clipItems } from "./data";
import Carousel from "react-material-ui-carousel";
import LandingButton from "../LandingPage/LandingButton";

const useStyles = makeStyles({
  AppContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  iFrame: {
    marginTop: "1%",
    padding: "10px",
    width: "31vw",
    height: "35vh",
  },
  imageContainer: {
    marginTop: "1%",
    paddingRight: "10%",
    paddingLeft: "10%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  videoTitle: {
    fontFamily: "IMMORTAL",
    fontStyle: "normal",
    fontWeight: 500,
    textShadow: "4px 4px 4px rgba(0,0,0,0.25)",
    background: `-webkit-linear-gradient(0.05turn,${Themecolors.brightRed},${Themecolors.darkestRed})`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    userSelect: "none",
  },
  itemContainer: {
    marginTop: "3%",
    width: "50vw",
    textAlign: "-webkit-center",
  },
  navButtonWrapper: {
    top: "43%",
  },
  navButtonsProps: {
    color: `${Themecolors.darkRed}`,
  },
  tabBarIndicator: {
    "& .MuiTabs-indicator": {
      backgroundColor: "transparent",
    },
  },
  tab: {
    height: "fit-content",
    width: "min-content",
    alignSelf: "center",
    opacity: 1,
    padding: 0,
    cursor: "default",
  },
  box: {
    width: "100%",
  },
  imgStyle: {
    width: "100%",
    cursor: "zoom-in",
  },
  imgContStyle: {
    width: "100%",
    margin: "1%",
  },
});

const GalleryPage = () => {
  const classes = useStyles();
  const [source, setSource] = useState(false);
  const [image, setImage] = useState(null);
  const [value, setValue] = useState(0);

  const handleImageClick = (image) => {
    setImage(image);
    setSource(true);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div {...other}>
        {value === index && <Box className={classes.box}>{children}</Box>}
      </div>
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="xl" disableGutters className={classes.AppContainer}>
      <AppBar
        position="static"
        style={{
          backgroundColor: "transparent",
          boxShadow: "none",
          paddingTop: "1%",
          zIndex:1
        }}
      >
        <Tabs
          className={classes.tabBarIndicator}
          centered
          value={value}
          onChange={handleChange}
          aria-label="tabBar"
          style={{ backgroundColor: "transparent" }}
        >
          <Tab
            disableRipple
            className={classes.tab}
            label={<LandingButton title="IMAGES" />}
          />
          <Tab
            disableRipple
            className={classes.tab}
            label={<LandingButton title="VIDEOS" />}
          />
          <Tab
            disableRipple
            className={classes.tab}
            label={<LandingButton title="CLIPS" />}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {imageItems.map((item, i) => (
          <Grid
            container
            alignItems="center"
            justify="space-evenly"
            direction="row"
            key={i}
          >
            <Grid item sm="3" className={classes.imgContStyle}>
              <img
                src={item.card.src}
                className={classes.imgStyle}
                onClick={() => handleImageClick(item.card.src)}
              />
            </Grid>
            <Grid item sm="3" className={classes.imgContStyle}>
              <img
                src={item.card.src2}
                className={classes.imgStyle}
                onClick={() => handleImageClick(item.card.src2)}
              />
            </Grid>
            <Grid item sm="3" className={classes.imgContStyle}>
              <img
                src={item.card.src3}
                className={classes.imgStyle}
                onClick={() => handleImageClick(item.card.src3)}
              />
            </Grid>
          </Grid>
        ))}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Carousel
          autoPlay={false}
          animation="slide"
          navButtonsAlwaysVisible
          fullHeightHover={false}
          navButtonsWrapperProps={{ className: classes.navButtonWrapper }}
          navButtonsProps={{ className: classes.navButtonsProps }}
        >
          {youtubeItems.map((item, i) => (
            <div className={classes.itemContainer}>
              <Typography className={classes.videoTitle} variant="h5" center>
                {item.title}
              </Typography>
              <div className={classes.iFrame} key={i}>
                <iframe
                  width="100%"
                  height="100%"
                  src={`//www.youtube.com/embed/${item.embed}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>
            </div>
          ))}
        </Carousel>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Carousel
          autoPlay={false}
          animation="slide"
          navButtonsAlwaysVisible
          fullHeightHover={false}
          navButtonsWrapperProps={{ className: classes.navButtonWrapper }}
          navButtonsProps={{ className: classes.navButtonsProps }}
        >
          {clipItems.map((item, i) => (
            <div className={classes.itemContainer}>
              <Typography className={classes.videoTitle} variant="h5" center>
                {item.title}
              </Typography>
              <div className={classes.iFrame} key={i}>
                <iframe
                  width="100%"
                  height="100%"
                  src={`//www.youtube.com/embed/${item.embed}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>
            </div>
          ))}
        </Carousel>
      </TabPanel>
      <Dialog maxWidth="lg" open={source} onClose={() => setSource(false)}>
        <img src={image} onClick={() => setSource(false)} />
      </Dialog>
    </Container>
  );
};

export default GalleryPage;