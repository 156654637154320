import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "@material-ui/core";
import { applyFormLink } from "./data";
import { Themecolors } from "../Theming/Theming";

const useStyles = makeStyles({
  button: {
    fontFamily: "IMMORTAL",
    height: "5%",
    color: "#f0f8ff",
    padding: 20,
    borderRadius: 99,
    "&:hover": {
      backgroundColor: "#f0f8ff",
      color: Themecolors.darkRed
    },
    "& .MuiTouchRipple-child": {
      backgroundColor: "#000000",
    },
  },
  linkTitle: {
    color: "inherit",
  },
});

const LandingLinkButton = ({ title }) => {
  const classes = useStyles();

  return (
    <>
      <Link
        // href={`${applyFormLink}`}
        rel="nonreferrer"
        target="_blank"
        underline="none"
        className={classes.button}
      >
        {title}
      </Link>
    </>
  );
};

export default LandingLinkButton;
