import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import "../index.css";
import { Themecolors } from "../Theming/Theming";

const useStyles = makeStyles({
  contentContainer: {
    deisplay: "flex",
    flexDirection: "column",
    justifyItems: "space-between",
    alignItems: "center",
  },
  typography: {
    fontFamily: "IMMORTAL",
    fontStyle: "normal",
    fontWeight: 500,
    alignItems: "center",
    textAlign: "center",
    textShadow: "4px 4px 4px rgba(0,0,0,0.25)",
    background: `-webkit-linear-gradient(0.05turn,${Themecolors.brightRed},${Themecolors.darkestRed})`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  typographyH4: {
    fontFamily: "IMMORTAL",
    fontStyle: "normal",
    fontWeight: 500,
    alignItems: "center",
    textAlign: "center",
    textShadow: "4px 4px 4px rgba(0,0,0,0.5)",
    color: "#f0f8ff",
  },
});

const LandingContent = () => {
  const classes = useStyles();

  return (
    <div className={classes.contentContainer}>
      <Typography variant="h1" className={classes.typography}>
        TEAM RED
      </Typography>
      <Typography variant="h4" className={classes.typographyH4}>
        DEFIAS BROTHERHOOD EU
      </Typography>
    </div>
  );
};

export default LandingContent;
