import AchievementRunSoD from "../assets/gallery/AchievementRunSoD.jpg";
import BenchCrew from "../assets/gallery/BenchCrew.jpg";
import DormaM from "../assets/gallery/dormam.jpg";
import Fate from "../assets/gallery/FatescribeM.png";
import GuardM from "../assets/gallery/guardianm.png";
import Kt from "../assets/gallery/KTM.png";
import Nerzh from "../assets/gallery/nerzm.png";
import PainM from "../assets/gallery/painm.jpg";
import RedArt from "../assets/gallery/RedArt.jpg";
import RedRaceDay from "../assets/gallery/RedRaceDay.jpg";
import RedRaceDay2 from "../assets/gallery/RedRaceDay2.jpg";
import RedXmas from "../assets/gallery/RedXmas.jpg";
import RedXmas2 from "../assets/gallery/RedXmas2.jpg";
import ShamansHavingFun from "../assets/gallery/ShamansHavingFun.jpg";
import ShriekM from "../assets/gallery/ShriekM.jpg";
import SireHC from "../assets/gallery/SireHC.jpg";
import SLGm from "../assets/gallery/SLGM.jpg";
import SludgeM from "../assets/gallery/SludgeM.png";
import SWZombies from "../assets/gallery/SWZombies.jpg";
import Sylvanas from "../assets/gallery/Sylvanas.png";
import mogcomp1 from "../assets/gallery/XmasMog1.jpg";
import mogcomp2 from "../assets/gallery/XmasMog2.jpg";
import datheaM from "../assets/gallery/DatheaMythic.png";
import kurogM from "../assets/gallery/KurogMythic.png";
import sennarthM from "../assets/gallery/SennarthMythic.png";
import terrosM from "../assets/gallery/TerrosMythic.png";
import councilM from "../assets/gallery/CouncilMythic.png";
import vaultGlory from "../assets/gallery/VaultGlory.jpg";

export const clipItems=[
  {
    title:"Xeno being himself",
    embed:"OQ6EPRy5nQs"
  },
  {
    title:"Xeno repair GOD",
    embed:"8Lfq9M9sBY8"
  },
  {
    title: "Hydrocore farm",
    embed: "jYGOblHBoOQ"
  },
  {
    title: "Baz doesn't speak often",
    embed: "x3BXiRjlgRc"
  },
  {
    title: "Motivational Speech",
    embed: "FSFJSNH7Bak"
  },
]

export const youtubeItems = [
  {
    title: "Mythic Dathea, Ascended",
    embed: "HUI37Y2Q4kU"
  },
  {
    title: "Mythic Kurog Grimtotem",
    embed: "CyyQBfvUKMM"
  },
  {
    title: "Mythic Sennarth",
    embed: "0Co_HkNrCvY"
  },
  {
    title: "Mythic Terros",
    embed: "2dh6RNYfA_A"
  },
  {
    title: "Mythic Primal Council",
    embed: "bmfL7qSmWRA"
  },
  {
    title: "Mythic Eranog",
    embed: "Pm-LrqOPa6g"
  },
  {
    title: "Mythic Queen Azshara",
    embed: "QrLM768NX1s"
  },
  {
    title: "Mythic NZoth",
    embed: "W9caacgi-m8"
  },
  {
    title: "Mythic Eye of the Jailer",
    embed: "uiRwWknjt5E"
  },
  {
    title: "Mythic The Nine",
    embed: "gZNU_XsArHs"
  },
  {
    title: "Mythic Soulrender Dormazain",
    embed: "hIQMaqQ9oD0"
  },
  
];

export const imageItems = [
  {
    card: {
      src: vaultGlory,
      src2: datheaM,
      src3: kurogM,
    },
  },
  {
    card: {
      src: sennarthM,
      src2: terrosM,
      src3: councilM,
    },
  },
  {
    card: {
      src: BenchCrew,
      src2: RedRaceDay,
      src3: RedRaceDay2
    }, 
  },
  {
    card: {
      src: RedXmas,
      src2: ShamansHavingFun,
      src3: SWZombies
    }
  },
  {
    card: {
      src: SireHC,
      src2: ShriekM,
      src3: SludgeM
    }
  },
  {
    card: {
      src: SLGm,
      src2: Sylvanas,
      src3: AchievementRunSoD
    }
  },
  {
    card: {
      src: Nerzh,
      src2: DormaM,
      src3: PainM,
    }
  },
  {
    card: {
      src: GuardM,
      src2: Fate,
      src3: Kt,
    }
  },
  {
    card: {
      src: mogcomp1,
      src2: mogcomp2,
    }
  },

];