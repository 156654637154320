import React from "react";
import { Button, Container, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Themecolors } from "../Theming/Theming";

const useStyles = makeStyles({
  button: {
    fontFamily: "IMMORTAL",
    height: "5%",
    color: "#f0f8ff",
    padding: 20,
    borderRadius: 40,
    "&:hover": {
      backgroundColor: "#f0f8ff",
      color: Themecolors.darkRed
    },
    "& .MuiTouchRipple-child": {
      backgroundColor: "#000000",
    },
  },
});

const LandingButton = ({ title }) => {
  const classes = useStyles();

  return <Button className={classes.button}>{title}</Button>;
};

export default LandingButton;
